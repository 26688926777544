import React from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import { FaShoppingBasket, FaStoreAlt, FaUsers, FaSignOutAlt } from "react-icons/fa";
import { Flex, Box, Text, Spacer, Icon, InputGroup, InputLeftElement, InputLeftAddon, Input } from "@chakra-ui/react";

import Orders from "./pages/orders/Orders";
import ViewOrder from "./pages/orders/ViewOrder";
import ViewOrders from "./pages/orders/ViewOrders";
import AddOrders from "./pages/orders/AddOrders";
import EditOrders from "./pages/orders/EditOrders";

import Stores from "./pages/stores/Stores";
import AddStores from "./pages/stores/AddStores";
import EditStore from "./pages/stores/EditStore";

import Users from "./pages/users/Users";
import ViewUser from "./pages/users/ViewUser";
import ViewUsers from "./pages/users/ViewUsers";
import AddUsers from "./pages/users/AddUsers";
import EditUsers from "./pages/users/EditUsers";

import Signin from "./pages/login/Signin";

import { useAuthentication } from "./wrappers/UseAuthentication";
//#00B0B9
//#D0DF00
const Layout = () => {
  const { isAuthenticated } = useAuthentication();
  return (
    <Router>
      <Flex flexDirection="column">
        <Flex as="nav" align="center" bg="#00B0B9" color="white" p={4}>
          {isAuthenticated && (
            <>
              <NavLink to="/stores" activeClassName="active" style={{ marginRight: "20px" }}>
                <Box display="flex" alignItems="center">
                  <FaStoreAlt />
                  <Text ml={2}>Stores</Text>
                </Box>
              </NavLink>
              <NavLink to="/orders" activeClassName="active" style={{ marginRight: "20px" }}>
                <Box display="flex" alignItems="center">
                  <FaShoppingBasket />
                  <Text ml={2}>Orders</Text>
                </Box>
              </NavLink>
              <NavLink to="/users" activeClassName="active" style={{ marginRight: "20px" }}>
                <Box display="flex" alignItems="center">
                  <FaUsers />
                  <Text ml={2}>Users</Text>
                </Box>
              </NavLink>
            </>
          )}
          {!isAuthenticated && (
            <NavLink to="/signin" style={{ marginRight: "20px" }}>
              <Text>Sign In</Text>
            </NavLink>
          )}
        </Flex>

        <Box p={4}>
          <Routes>
            <Route path="/signin" element={<Signin />} />
            {isAuthenticated && (
              <>
                <Route path="/orders" element={<Orders />} />
                <Route path="/viewOrder/:id" element={<ViewOrder />} />
                <Route path="/viewOrders" element={<ViewOrders />} />
                <Route path="/addOrders" element={<AddOrders />} />
                <Route path="/editOrders" element={<EditOrders />} />

                <Route path="/stores" element={<Stores />} />

                <Route path="/addStores" element={<AddStores />} />
                <Route path="/editStore/:id" element={<EditStore />} />

                <Route path="/users" element={<Users />} />
                <Route path="/viewUsers" element={<ViewUsers />} />
                
                <Route path="/viewUser/:id" element={<ViewUser />} />
                <Route path="/addUsers" element={<AddUsers />} />
                <Route path="/editUsers" element={<EditUsers />} />
              </>
            )}
            <Route path="/" element={<Signin />} />
          </Routes>
        </Box>
      </Flex>
    </Router>
  );
};

export default Layout;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Heading, Input, Button, VStack, HStack, useToast } from "@chakra-ui/react";

const ViewUser = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [commsLog, setCommsLog] = useState([]);
  const [newCommTitle, setNewCommTitle] = useState("");
  const [newCommBody, setNewCommBody] = useState("");
  const [newCommType, setNewCommType] = useState("PUSH NOTIFICATION");
  const [newCommCategory, setNewCommCategory] = useState("Admin Diginu");
  const toast = useToast();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/get-user`, { user_id: id });
        toast({
          title: response.data.message,
          description: response.data.description,
          status: response.data.status,
          duration: 3000,
          isClosable: true,
        });
        setUser(response.data.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCommsLog = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/get-comms-log`, { user_id: id });
        setCommsLog(response.data.data);
      } catch (error) {
        console.error("Error fetching communications log:", error);
      }
    };

    fetchUser();
    fetchCommsLog();
  }, [id]);

  const handleSendComm = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/send-comm`, {
        from_user_id:localStorage.getItem('user_id'),
        to_user_id: user.id,
        comms_type:newCommType,
        comms_category:newCommCategory,
        msg_title:newCommTitle,
        msg_body:newCommBody,
        msg_data: '' 
        });

      toast({
        title: response.data.message,
        description: response.data.description,
        status: response.data.status,
        duration: 3000,
        isClosable: true,
      });
      const refreshedCommsLog = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/get-comms-log`, { user_id: id });
      setCommsLog(refreshedCommsLog.data.data);
      setNewCommTitle('');
      setNewCommBody('');
    } catch (error) {
      console.error("Error sending communication:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!user) {
    return <Heading>User '{id}' not found</Heading>;
  }

  return (
    <Box padding={4} display="flex" justifyContent="space-between">
      <Box width="35%">
        <Heading mb={4}>User Details</Heading>

        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th>Field</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(user).map(([key, value]) => (
              <Tr key={key}>
                <Td>{key}</Td>
                <Td>{value !== null ? value.toString() : "N/A"}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box width="63%">
        <Heading mb={4}>Comms Log</Heading>
        <Box height="480px" overflowY="auto">
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>expo UUID</Th>
                <Th>expo status</Th>
                <Th>Created At</Th>
                <Th>From User ID</Th>
                <Th>To User ID</Th>
                <Th>Comms Type</Th>
                <Th>Comms Category</Th>
                <Th>Msg Title</Th>
                <Th>Msg Body</Th>
                <Th>Active</Th>
              </Tr>
            </Thead>
            <Tbody>
              {commsLog.map((comm) => (
                <Tr key={comm.id}>
                  <Td>{comm.expo_uuid}</Td>
                  <Td>{comm.expo_status}</Td>
                  <Td>{formatDate(comm.created_at)}</Td>
                  <Td>{comm.from_user_id}</Td>
                  <Td>{comm.to_user_id}</Td>
                  <Td>{comm.comms_type}</Td>
                  <Td>{comm.comms_category}</Td>
                  <Td>{comm.msg_title}</Td>
                  <Td>{comm.msg_body}</Td>
                  <Td>{comm.active_yn ? "Yes" : "No"}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <VStack mt={8} spacing={2}>
          <label>Send Push Notification</label>
          <Box width="100%">
            <label>Message Title</label>
            <Input placeholder="Enter message title..." value={newCommTitle} onChange={(e) => setNewCommTitle(e.target.value)} size="sm" />
          </Box>
          <Box width="100%">
            <label>Message Body</label>
            <Input placeholder="Enter message body..." value={newCommBody} onChange={(e) => setNewCommBody(e.target.value)} size="sm" />
          </Box>

          <Button onClick={handleSendComm} size="sm">
            Send
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default ViewUser;

import React, { useState, useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, useToast,Button} from "@chakra-ui/react";


const ViewOrder = () => {
  const { id } = useParams(); // Assuming the URL contains the order ID as a parameter
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/get-order`, {
        hcart_id: id,
      });
      const { data, message, description } = response.data;
      toast({
        title: message,
        description: description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setOrderDetails(data);
    } catch (error) {
      console.error("Error fetching order details:", error);
      toast({
        title: "Failed to load order details",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  return (
    <Box overflowX="auto">
         <Button onClick={() => navigate(-1)} mb={4}>Back</Button>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
          <Spinner size="xl" />
        </Box>
      ) : (
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th width="150px">Product description</Th>
              <Th width="100px">Quantity</Th>
              <Th width="150px">Coupon ID</Th>
              <Th width="250px">Customer Notes</Th>
              <Th width="250px">Log Notes</Th>
              <Th width="100px">Charge</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderDetails.map((row) => (
              <Tr key={row.hproducts_id}>
                <Td>{row.wp_description_display1}</Td>
                <Td>{row.qty}</Td>
                <Td>{row.coupon_id}</Td>
                <Td>{row.customer_notes}</Td>
                <Td>{row.log_notes}</Td>
                <Td>{row.charge}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default ViewOrder;

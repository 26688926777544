// src/components/SignIn.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FormControl, FormLabel, Input, Button, Stack, Link, useToast } from "@chakra-ui/react";
import { useAuthentication } from "../../wrappers/UseAuthentication";

const SignIn = () => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const { signIn } = useAuthentication();
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    /*
    setFormIsSubmitting(true);
    const examplePromise = new Promise((resolve, reject) => {
      setTimeout(() => resolve(200), 3000);
    });
    toast.promise(examplePromise, {
      success: { title: "Sign in Successful!)", description: "Looks great" },
      error: { title: "Sign in Failed!", description: "Something wrong" },
      loading: { title: "Signing in...", description: "Please wait" },
    });
    setTimeout(() => {
      signIn(); //hook
      setFormIsSubmitting(false);
      navigate("/orders");
    }, 3000);
*/
    setFormIsSubmitting(true);
  //https://alphacluster.digitalfields.co.za/diginu_admin/api/v1/dummy/WP-login
    //http://localhost:3111/diginu_admin/api/v1/dummy/WP-login

    axios
      .post(`${process.env.REACT_APP_API_URL}/dummy/WP-login`, {
        username: email,
        password: password,
      })
      .then((response) => {
        setFormIsSubmitting(false);
        let status=response.status;
        if(status==200){
          let {huserID,token,wp_dgn_referral_code}=response.data.data;
          localStorage.setItem('user_id',huserID);
          toast({
            title: response.data.message,
            description: response.data.description,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          signIn(); // Assuming signIn is a function from a hook
          navigate("/users");
        }
        else{
          toast({
            title: response.data.message,
            description: response.data.description,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

      })
      .catch((error) => {
        setFormIsSubmitting(false);
        let errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
        toast({
          title: "Sign in Failed!",
          description: errMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

  };

  // Call auto sign-in on component mount
  useEffect(() => {
    // Implement your auto sign-in logic here
    // Redirect to home if user is signed in
  }, []);

  return (
    <FormControl maxW="400px" mx="auto" mt={8} p="4" borderWidth="1px" borderRadius="lg">
      <form onSubmit={handleSignIn}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input type="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </FormControl>
          <Button
            type="submit"
            colorScheme="blue"
            bg="#00B0B9"
            isLoading={formIsSubmitting} // Set isLoading to true when submitting the form
            loadingText="Signing In"
          >
            Sign in
          </Button>
          <Link color="#D0DF00" textAlign="center" fontSize="sm" fontWeight={"bold"}>
            Forgot password?
          </Link>
        </Stack>
      </form>
    </FormControl>
  );
};

export default SignIn;

import React from "react";
import { Box, Heading, Flex, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, IconButton, Tooltip } from "@chakra-ui/react";
import { FaEdit, FaSave } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import StoreTable from "./components/EditStoreTable";
import StoreHoursTable from "./components/EditStoreHoursTable";
import ProductsTable from "./components/EditStoreProductsTable";

const EditStore = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Box>
      <Flex justify="space-between" flexDirection={"column"} gap={4}>
        <Heading size="lg">Edit Store details</Heading>
        <StoreTable id={id} />
        <Box width="100%" mt={8}>
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Products
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <ProductsTable  id={id} />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Store Opening Hours
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <StoreHoursTable id={id} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Flex>
    </Box>
  );
};

export default EditStore;

import React from 'react';
import { Box, Button} from '@chakra-ui/react';
import { FaEye } from 'react-icons/fa';

const ViewUsers = ({ navigateToSection }) => {
    return (
      <Box>
        <Button
          onClick={() => navigateToSection('personal-information')}
          variant="outline"
          colorScheme="blue"
          justifyContent="flex-start"
          leftIcon={<FaEye />}
        >
          View Users
        </Button>
      </Box>
    );
  };

export default ViewUsers;
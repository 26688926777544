import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Box, Button, Spinner, useToast, Heading, Flex } from "@chakra-ui/react";

const EditProductAddons = ({ productId, productDescription, onBack }) => {
  const [addons, setAddons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchAddons = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/get-addons`, { wp_product_id: productId });
        setAddons(response.data.data);
        toast({
          title: response.data.message,
          description: '',
          status: response.data.status,
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch addons",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAddons();
  }, [productId, toast]);

  if (isLoading) {
    return <Spinner size="lg" />;
  }

  return (
    <Box>
      <Flex justify="space-between" flexDirection={"row"} gap={4}>
        <Heading size="lg">{productDescription}</Heading>
        <Button onClick={onBack} mb={4}>
          Back
        </Button>
      </Flex>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Type</Th>
            <Th>Grouping</Th>
            <Th>Group Order</Th>
            <Th>Selection Type</Th>
            <Th>Selection Display</Th>
            <Th>Group Title Display</Th>
            <Th>Option Display</Th>
            <Th>Charge</Th>
          </Tr>
        </Thead>
        <Tbody>
          {addons.map((addon) => (
            <Tr key={addon.id}>
              <Td>{addon.id}</Td>
              <Td>{addon.type}</Td>
              <Td>{addon.grouping}</Td>
              <Td>{addon.group_order}</Td>
              <Td>{addon.selection_type}</Td>
              <Td>{addon.selection_display}</Td>
              <Td>{addon.group_title_display}</Td>
              <Td>{addon.option_display}</Td>
              <Td>{addon.charge}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default EditProductAddons;

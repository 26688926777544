import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, IconButton, Select, Input, useToast, Tooltip, Box, Heading, Flex } from "@chakra-ui/react";
import { FaSave, FaTrash, FaPlus } from "react-icons/fa";

const EditStoreHoursTable = ({ id }) => {
  const toast = useToast();
  const [storeHours, setStoreHours] = useState([]);
console.log('test');
  useEffect(() => {
    const fetchStoreHours = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/get-store-hours`, { store_id: id });
        const formattedStoreHours = response.data.data.map((hour) => ({
          ...hour,
          start_time: formatTime(hour.start_time),
          end_time: formatTime(hour.end_time),
        }));
        setStoreHours(formattedStoreHours);
      } catch (error) {
        console.error("Error fetching store hours:", error);
      }
    };

    fetchStoreHours();
  }, [id]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 100);
    const minutes = time % 100;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  const handleHoursChange = (index, field, value) => {
    const newStoreHours = [...storeHours];
    newStoreHours[index][field] = value;
    setStoreHours(newStoreHours);
  };

  const handleSaveHoursClick = async (index) => {
    const storeHour = storeHours[index];
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/dummy/upd-store-hours`, storeHour);
      toast({
        title: "Store hours updated",
        description: "The store hours have been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating store hours:", error);
      toast({
        title: "Update failed",
        description: "There was an error updating the store hours.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteHoursClick = async (index) => {
    const storeHour = storeHours[index];
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/dummy/del-store-hours`, { id: storeHour.id });
      toast({
        title: "Store hours deleted",
        description: "The store hours have been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setStoreHours(storeHours.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting store hours:", error);
      toast({
        title: "Delete failed",
        description: "There was an error deleting the store hours.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddHoursClick = () => {
    setStoreHours([
      ...storeHours,
      {
        id: null,
        dow: 0,
        start_time: "00:00",
        end_time: "00:00",
        hstore_id: id,
      },
    ]);
  };

  return (
    <Box>
      <Heading size="md" mb={4}>
        Store Opening Hours
      </Heading>
      <Flex justify="flex-end" mb={2}>
        <Tooltip label="Add new hours" aria-label="Add new hours">
          <IconButton icon={<FaPlus />} onClick={handleAddHoursClick} colorScheme="blue" size="sm" />
        </Tooltip>
      </Flex>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Day</Th>
            <Th>Start Time</Th>
            <Th>End Time</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {storeHours.map((hour, index) => (
            <Tr key={index}>
              <Td>
                <Select value={hour.dow} onChange={(e) => handleHoursChange(index, "dow", e.target.value)} size="sm">
                  <option value="0">Monday</option>
                  <option value="1">Tuesday</option>
                  <option value="2">Wednesday</option>
                  <option value="3">Thursday</option>
                  <option value="4">Friday</option>
                  <option value="5">Saturday</option>
                  <option value="6">Sunday</option>
                </Select>
              </Td>
              <Td>
                <Input type="time" value={hour.start_time} onChange={(e) => handleHoursChange(index, "start_time", e.target.value)} size="sm" />
              </Td>
              <Td>
                <Input type="time" value={hour.end_time} onChange={(e) => handleHoursChange(index, "end_time", e.target.value)} size="sm" />
              </Td>
              <Td>
                <Tooltip label="Save changes" aria-label="Save changes">
                  <IconButton icon={<FaSave />} onClick={() => handleSaveHoursClick(index)} colorScheme="green" mr={2} size="sm" />
                </Tooltip>
                <Tooltip label="Delete" aria-label="Delete">
                  <IconButton icon={<FaTrash />} onClick={() => handleDeleteHoursClick(index)} colorScheme="red" size="sm" />
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default EditStoreHoursTable;

import React from 'react';
import ChakraWrapper from './wrappers/ChakraWrapper'; 
import Layout from './_layout';
import {AuthenticationProvider} from './wrappers/UseAuthentication';
function App() {
  return (
    <ChakraWrapper>
      <AuthenticationProvider>
      <Layout />
      </AuthenticationProvider>
    </ChakraWrapper>
  );
}

export default App;

import React from 'react';
import { Box, Button} from '@chakra-ui/react';
import { FaEdit} from 'react-icons/fa';

const EditUsers = () => {
    return (
      <Box>
        <Button
          onClick={() => alert('test')}
          variant="outline"
          colorScheme="blue"
          justifyContent="flex-start"
          leftIcon={<FaEdit />}
        >
          Edit Users
        </Button>
      </Box>
    );
  };

export default EditUsers;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Input, InputGroup, InputRightElement, Table, Thead, Tbody, Tr, Th, Td, Button, Spinner, IconButton, HStack, useToast } from "@chakra-ui/react";
import { FaSearch, FaTimesCircle, FaEdit } from "react-icons/fa";

const Orders = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const handleView = (id) => {
    console.log(`Edit button clicked for ID: ${id}`);
    navigate(`/ViewOrder/${id}`);
  };

  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 8;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchString, setSearchString] = useState("");

  const fetchOrders = async (page) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dummy/get-orders`, {
        searchString,
        itemsPerPage,
        currentPage: page,
      });
      const { orders, totalCount, message, description } = response.data;
      toast({
        title: message,
        description: description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setOrders(orders);
      setTotalCount(totalCount);
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast({
        title: "Failed to load orders",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage, searchString]);

  const handleSearch = () => {
    setSearchString(searchTerm);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setSearchString("");
    fetchOrders(1);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    } else if (e.key === "Escape") {
      clearSearch();
    }
  };

  const pagesCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxButtons = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    const endPage = Math.min(pagesCount, startPage + maxButtons - 1);

    if (startPage > 1) {
      pageButtons.push(
        <Button key={1} onClick={() => handlePageChange(1)} isActive={1 === currentPage}>
          1
        </Button>
      );
      if (startPage > 2) {
        pageButtons.push(
          <Button key="ellipsis-start" disabled>
            ...
          </Button>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <Button key={i} onClick={() => handlePageChange(i)} isActive={i === currentPage}>
          {i}
        </Button>
      );
    }

    if (endPage < pagesCount) {
      if (endPage < pagesCount - 1) {
        pageButtons.push(
          <Button key="ellipsis-end" disabled>
            ...
          </Button>
        );
      }
      pageButtons.push(
        <Button key={pagesCount} onClick={() => handlePageChange(pagesCount)} isActive={pagesCount === currentPage}>
          {pagesCount}
        </Button>
      );
    }

    return pageButtons;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: '2-digit', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', options).replace(',', '');
    return formattedDate;
  };
  
  return (
    <Box overflowX="auto">
      <InputGroup mb={4} width="300px">
        <Input placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyDown={handleKeyDown} />
        <InputRightElement>
          {searchTerm && <IconButton icon={<FaTimesCircle />} size="sm" onClick={clearSearch} aria-label="Clear search" mr={2} />}
          <IconButton icon={<FaSearch />} size="sm" onClick={handleSearch} aria-label="Search" />
        </InputRightElement>
      </InputGroup>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
          <Spinner size="xl" />
        </Box>
      ) : (
        <>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th width="50px">ID</Th>
                <Th width="150px">Created At</Th>
                <Th width="150px">Store ID</Th>
                <Th width="150px">Customer ID</Th>
                <Th width="100px">Status</Th>
                <Th width="150px">Product Total</Th>
                <Th width="100px">Delivery Fee</Th>
                <Th width="100px">Driver Tip</Th>
                <Th width="100px">Admin Fee</Th>
                <Th width="100px">Discount</Th>
                <Th width="100px">Delivery</Th>
                <Th width="100px">View</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((row) => (
                <Tr key={row.id}>
                  <Td>{row.id}</Td>
                  <Td>{formatDate(row.created_at).toLocaleString()}</Td>
                  <Td>{row.hstore_id}</Td>
                  <Td>{row.customer_id}</Td>
                  <Td>{row.cart_status}</Td>
                  <Td>{row.product_total_price}</Td>
                  <Td>{row.delivery_fee}</Td>
                  <Td>{row.driver_tip}</Td>
                  <Td>{row.admin_fee}</Td>
                  <Td>{row.product_total_price_discount}</Td>
                  <Td>{row.deliveryyn ? 'Yes' : 'No'}</Td>
                  <Td>
                    <Button onClick={() => handleView(row.id)} leftIcon={<FaEdit />}>
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <HStack justifyContent="center" mt={4}>
            <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            {renderPageButtons()}
            <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pagesCount}>
              Next
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Orders;

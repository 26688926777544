import React from 'react';
import { Box, Button} from '@chakra-ui/react';
import { FaPlus} from 'react-icons/fa';

const AddOrders = () => {
  return (
    <Box>
      <Button
        onClick={() => alert('test')}
        variant="outline"
        colorScheme="blue"
        justifyContent="flex-start"
        leftIcon={<FaPlus />}
      >
        Add Orders
      </Button>
    </Box>
  );
};

export default AddOrders;